import 'magnific-popup';
import { get } from 'axios';
import { URL_RESOURCES_PREFIX, URL_RESOURCES_SUFFIX, LIVETEX_IDS } from './constants';

/**
 * Parsing URL
 * @param url
 * @returns {{}}
 */
export function getParamsFromURL(url) {
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    const obj = {};

    if (queryString) {
        queryString = queryString.split('#')[0];
        const arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            const a = arr[i].split('=');
            const paramName = a[0];
            obj[paramName] = typeof a[1] === 'undefined' ? true : a[1];
        }
    }

    return obj;
}

/**
 * Check value is numeric
 */
export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

/**
 * Logging network errors to console
 */
export const simpleHandleError = error => console.log(error);

/**
 * Set conjugation for nouns
 * @param number
 * @param titles
 * @returns {*}
 */
export function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
}

/**
 * Date formatter
 * @param {number} ms - milliseconds for fomatting
 * @returns {string} - formatted time
 */
export function formatDDMMYYHM(ms) {
    const date = new Date(ms);
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let min = date.getMinutes();

    m = m < 10 ? `0${m}` : m;
    d = d < 10 ? `0${d}` : d;
    h = h < 10 ? `0${h}` : h;
    min = min < 10 ? `0${min}` : min;

    return `${d}.${m}.${y}, ${h}:${min}`;
}

/**
 * Working with cookies
 * @type {{set: ((name, value, days?)), get: ((name)), delete: ((name))}}
 */
export const cookieUtils = {
    set(name, value, days) {
        let expires;
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toGMTString()}`;
        } else {
            expires = '';
        }
        document.cookie = `${name}=${value + expires}; path=/`;
    },
    get(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    delete(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    },
};

/**
 * Download file from server
 * @param {string} url
 */
export function downloadFile(url) {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.style.display = 'none';
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
}

/**
 * Modals - call magnific popup plugin
 */
export function initCommonPopup(additionSelector) {
    const selector = additionSelector ? `${additionSelector}.btn_popup` : '.btn_popup'
    $(selector).magnificPopup({
        type: 'inline',
        midClick: true,
        mainClass: 'mfp-fade',
        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
        callbacks: {
            close: function close() {
                if ($('.btn_popup').hasClass('btn_popup_tour')) {
                    window.location.reload();
                }
            },
        },
    });

    $('.btn_after_close').click(function () {
        $.magnificPopup.close();
        window.location.reload();
    })
}

/**
 * Create modal with custom text
 * @param {string} title
 * @param {string} info
 */
export function openInfoPopup(title, info) {
    $('.info-popup .popup__header').html(title);
    $('.info-popup .popup__text').html(info);
    $.magnificPopup.open({
        items: {
            src: '.info-popup',
        },
        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
    });

    $('.info-popup__button-close').click(() => {
        $.magnificPopup.close();
    });
}

/**
 * Ellipsis text1
 */
export function ellipsisText() {
    $('.ellipsis-text').hover(
        function () {
            const elWidth = $(this).width();
            $(this).addClass('active');
            const trueWidth = $(this).width();
            const widthDiff = trueWidth - elWidth;
            const normSpeed = parseInt(widthDiff / 100, 10) * 1000;
            const scrollSpeed = normSpeed > 50 ? normSpeed : 500;

            $(this).animate(
                {
                    left: -widthDiff,
                },
                scrollSpeed,
            );
        },
        function () {
            $(this)
                .stop()
                .css('left', '0');
            $(this).removeClass('active');
        },
    );
}

/**
 * Help tooltips
 */
export function showHelpTooltip() {
    document.addEventListener('DOMContentLoaded', () => {
        const inputs = [...document.querySelectorAll('.form-block__input_tooltip')];
        inputs.forEach((input) => {
            input.addEventListener('focus', function () {
                const helpMsg = this.parentNode.querySelector('.form-block__tooltip_help');
                if (helpMsg) {
                    helpMsg.style.display = 'table';
                }
            });
            input.addEventListener('blur', function () {
                const helpMsg = this.parentNode.querySelector('.form-block__tooltip_help');
                if (helpMsg) {
                    helpMsg.style.display = 'none';
                }
            });
        });
    });
}

export function localStorageOpearate(paramName, type = 'get', paramValue) {
    switch (type) {
    case 'get':
        var gettedInfo = localStorage.getItem(paramName);
        return gettedInfo;
        break;
    case 'set':
        localStorage.setItem(paramName, paramValue);
        return false;
        break;
    case 'remove':
        localStorage.removeItem(paramName);
        return false;
        break;

    default:
        return false;
    }
}

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * Convert phone number from InputMasked plugin
 * @param {string} phone
 */
export const convertPhone = phone => `${phone.replace(/[(\)-]/g, '')}`;

/**
 * Detect IE 8+ and Edge
 */
export const isMSIE = () => {
    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        return true;
    }
    return false;
};

// TODO: Check for delete
/**
 * Cut email with ***
 * @param {string} email
 */
const cutEmail = (email) => {
    const parts = email.split('@');
    const cuttedEmailName = parts[0].slice(0, -3);
    return `${cuttedEmailName}***@${parts[1]}`;
};

// TODO: Check for delete
/**
 * Select emils for cutting with ***
 * @param {string} selector
 */
export const cutPlayersEmails = (selector) => {
    const emailNodes = [...document.querySelectorAll(selector)];

    emailNodes.forEach((emailNode) => {
        const email = emailNode.textContent;
        emailNode.textContent = cutEmail(email);
    });
};

/**
 * Cut url for without params
 * @param {string} url which insert
 */
export function cutUrl(url) {
    const protokolSplitted = url.split('://');
    const protokol = protokolSplitted[0];
    const domain = protokolSplitted[1].split('/')[0];
    return `${protokol}://${domain}`;
}

export function range(start, count) {
    return Array.apply(0, Array(count)).map((element, index) => index + start);
}

/**
 * Insert HTML element after another
 * @param elem Element which insert
 * @param refElem Element after with insert
 */
export const insertAfter = (elem, refElem) => refElem.parentNode.insertBefore(elem, refElem.nextSibling)

/**
 * Get random integer number
 * @param {number} min - minimal random value
 * @param {number} max - maximal random value
 */
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);


/**
 * Get scope for resources
 * @returns {Promise<void>}
 */
export const getResources = async (scope) => {
    try {
        const url = URL_RESOURCES_PREFIX + scope + URL_RESOURCES_SUFFIX;
        const { data } = await get(url);
        return data;
    } catch (err) {
        console.log(err.message);
    }
}

/**
 * Get LiveTex ID
 * @param {number} id - site clone ID
 * @return {number} - LiveTex ID
 */
export const getLiveTexId = (id = 1) => LIVETEX_IDS[id];


/**
 * @class Form
 */
export class Form {
    /**
     * Form constructor
     * @param { HTMLElement } elem - The form
     * @param { Boolean } isValid - Is the form valid
     * @param { HTMLElement } submitBtn - Button which submit the form
     * @param { Object } patterns - RegExp for validation
     */
    constructor(elem, submitBtn, isValid = false, patterns) {
        this.elem = elem;
        this.isValid = isValid;
        this.submitBtn = submitBtn || this.elem.querySelector('button');

        /**
         * RegExp for fields validation
         * @type {{digits: RegExp, letters: RegExp, email: RegExp, password: Regexp, qiwi: RegExp, visa_rus: RegExp, visa_rus_holder: RegExp, visa_uah_holder: RegExp, yandexmoney: RegExp, ecommpay: RegExp, webmoney: RegExp}}
         */
        this.patterns = {
            digits: /^\d+$/,
            letters: /[a-zA-Z]{2,}$/,
            email: /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([A-Za-z0-9_-]+\.)+[A-Za-z0-9]([A-Za-z0-9-])+$/i,
            password: /[\s\S]{6,120}/,
        };

        this.errorMessages = {
            name: 'Укажите имя',
            email: 'Неверный email',
            message: 'Поле с сообщением пустое',
        };

        this.addListeners();
    }

    addListeners() {
        const fieldsColl = this.elem.querySelectorAll(
            'input:not([type="hidden"]):not(.unrequired)',
        );
        const fields = [];
        const checkboxes = [];
        const rulesCheck = document.querySelector('#rules-check');

        rulesCheck.addEventListener('change', () => {
            const rulesError = document.createElement('span');
            rulesError.className = 'form-block__error form-block__error_rules';
            rulesError.textContent = 'Вы должны согласиться с правилами казино';
            if (rulesCheck.checked) {
                rulesCheck.classList.remove('checkbox-wrapp__checkbox_error');
                this.submitBtn.removeAttribute('disabled');
                $('.form-block__error_rules').remove();
            } else {
                rulesCheck.classList.add('checkbox-wrapp__checkbox_error');
                rulesCheck.parentNode.appendChild(rulesError);
                this.submitBtn.setAttribute('disabled', 'disabled');
            }
        });

        for (const field of fieldsColl) {
            if (field.type === 'checkbox') {
                checkboxes.push(field);
                this.validateCheckboxes(checkboxes);

                field.addEventListener('change', () => {
                    this.validateCheckboxes(checkboxes);
                });
            } else {
                const fieldProto = Object.assign({}, field.dataset);
                fieldProto.valid = false;
                fieldProto.value = field.value;
                fields.push(fieldProto);

                field.addEventListener('input', () => {
                    fieldProto.value = field.value;
                    this.validateInputs(fields);
                });

                field.addEventListener('paste', () => {
                    fieldProto.value = field.value;
                    this.validateInputs(fields);
                });
            }
        }
    }

    /**
     * @param { Array } fields - Form inputs
     */
    validateInputs(fields) {
        [...fields].forEach((field) => {
            const pattern = this.patterns[field.type] || /[\s\S]/;
            field.valid = pattern.test(field.value);
        });

        this.isInputsValid = fields.every(field => field.valid);
        this.validateAll();
    }

    validateCheckboxes(inputs) {
        this.allChecked = false;
        if (inputs.length === 0) {
            return (this.allChecked = true);
        }
        this.allChecked = inputs.every(input => input.checked);
        this.validateAll();
    }

    validateAll() {
        this.isValid = this.isInputsValid && this.allChecked;
    }
}

export function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__success').remove();
}

export function renderPlayerNames() {

    const playerCount = [...document.querySelectorAll('.player-count__number')];
    const playerNames = ['игрок', 'игрока', 'игроков'];

    playerCount.forEach(function(item){
        function renderNames(count) {
            let playerRendered = declOfNum(Number(count), playerNames);
            item.nextElementSibling.textContent = playerRendered
        }
        renderNames(parseInt(item.innerText));
    });
}


// Method for FormData take the form keys and values and put them in an object
export const objectFromFormData = formData => {
    const values = {};
    for (let [key, value] of formData.entries()) {
        if (values[key]) {
            if (!(values[key] instanceof Array)) {
                values[key] = new Array(values[key]);
            }
            values[key].push(value);
        } else {
            values[key] = value;
        }
    }
    return values;
}


// Captcha methods declaration
export function loadCaptcha() {
    var captcha = new Image();
    var param = Math.random().toString(36).slice(-5);
    captcha.src = '/service/captcha?rnd=' + param;
    captcha.className = 'captcha';
    captcha.alt = 'captcha';
    captcha.id = 'captcha-img';
    captcha.width = 121;
    captcha.height = 34;
    $('.captchaContainer').html(captcha);
}

export function showCaptcha() {
    loadCaptcha();
    $('.form-block_captcha').show();
}


export function initCaptcha() {
    loadCaptcha();

    $('body .btn_refresh').click(function (e) {
        e.preventDefault();
        loadCaptcha();
    });
}
