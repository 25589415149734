import Choices from '../../../../../libs/choices/js/choices'


export const initSelectCurrency = (formElement) => {
  // Select for currency init registration-form
  const currencyElement = formElement.querySelector('select[name=currency]')
  const currencyInstance = new Choices(currencyElement, {
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: '',
  });

  // Toggle for select tooltip
  currencyElement.addEventListener('showDropdown', () => {
    formElement.querySelector('span.select-wrapp__tooltip').style.display = 'table'
  }, false)
  currencyElement.addEventListener('hideDropdown', () => {
    formElement.querySelector('span.select-wrapp__tooltip').style.display = 'none'
  }, false)

  return { currencyElement, currencyInstance }
}
