export const ru = {
    translation: {
        RUB: "руб.",
        USD: "$",
        UAH: "грн",
        visa_fee: "Комиссия за вывод на карту составит {{comission}}",
        passwordTooltipAfterReg: 'Введите пароль из SMS',
        depositAlert: 'Мы заметили, что Вы за короткое время сделали несколько неуспешных попыток оплаты на нашем проекте. Возможно, существуют какие-либо ограничения в Вашем платежном методе или технические проблемы. Рекомендуем обратиться в службу поддержи для оказания Вам помощи в процессе оплаты.',
        alert: 'Оповещение',
    }
};

export const en = {
    translation: {
        RUB: "RUB",
        USD: "USD",
        UAH: "uah",
        passwordTooltipAfterReg: 'Введите пароль из SMS',
        depositAlert: 'Мы заметили, что Вы за короткое время сделали несколько неуспешных попыток оплаты на нашем проекте. Возможно, существуют какие-либо ограничения в Вашем платежном методе или технические проблемы. Рекомендуем обратиться в службу поддержи для оказания Вам помощи в процессе оплаты.',
        alert: 'Оповещение',
    }
};

export const resources = { ru, en };
