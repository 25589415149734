// Auth
export const URL_REG_MAIL = '/service/player/register/email';
export const URL_REG_PHONE = '/service/player/register/phone';
export const URL_AUTH = '/service/player/login';
export const URL_AUTH_CHECK_LOGIN = '/service/player/checkLogin';
export const URL_CHANGE_PASS = '/service/player/restoreCode';

export const URL_SEND_CODE = '/login/sendcode';
export const URL_OAUTH_LOGIN = '/oauth/login';

// Resources
export const URL_RESOURCES_PREFIX = '/service/scopes/';
export const URL_RESOURCES_SUFFIX = '/resources';

// Player
export const URL_PLAYER = '/service/player';
export const URL_PLAYER_POINTS = '/service/player/points';
export const URL_PLAYER_GOLD = '/service/players/current/hitThePot/points'; // GET
export const URL_GOLD_EXCHANGE = '/service/players/current/hitThePot/points'; // POST
export const URL_LOAD_BONUSES = '/service/player/bonuses';
export const URL_EMAIL_VERIFY = '/service/players/verifyEmail';
export const URL_CHECK_PROFILE = '/service/player/invalidFields';
export const URL_SECURITY = '/service/players/current/security-notification-prefs';
export const URL_GAME_FAV_CHECK = '/service/game/favorite';
export const URL_GAME_FAV_ADD = '/service/player/game/add';
export const URL_GAME_FAV_REMOVE = '/service/player/game/remove';
export const WEB_EVENTS_SETTINGS = '/service/players/current/events/settings';

// Games
export const GAME_IMG_DEF = '/img/games/game_def.jpg'; // Default game icon
export const GAME_IMG_DEF_WIN = '/img/games/game_def_win.png'; // Default winner icon
export const GAME_RULES = '/service/game/rules/'; // + {gameCode}
export const GAME_NAME = '/service/game/name/';// + {gameCode}

// Cashier
export const psVisa = 'visa_rus';
export const psVisa2 = 'card_rus_FTD';
export const psVisaUah = 'visa_uah';
export const PIASTRIX = 'piastrix';

export const URL_LAST_DEP = '/service/depositInfo';

export const URL_COUPON = '/service/couponInfo';
export const COUPON_COOKIE = 'couponCode';
export const LOGIN_COOKIE = 'openLogin';

// RegExp
export const REGEXP_EMAIL = /^[\w.-]+@[\w.-]{2,}\.\w{2,}$/i;

// Sorvi-bank
export const BANK_DATA = '/service/tournaments/hitThePot/currentGameGroup'; // + /{gameProvider}/{gameCode}
export const BANK_LEADERS = '/service/tournaments/hitThePot/currentGameGroup'; // + /{gameProvider}/{gameCode}/{count}
export const BANK_ROUND_END = '/service/tournaments/hitThePot/endRoundDate';
export const BANK_START_SUPER = '/service/tournaments/hitThePot/startSupergameRoundDate';
export const GOLD_COEFFICIENT = '/service/tournaments/hitThePot/goldConvertCoefficient';

export const BANK_EVENTS = '/ws/subscriptions?access_token='; // + access token from cookie('at')
export const BANK_EVENTS_UPDATE = 'tournament.event.update';
export const BANK_EVENTS_ROUND_END = 'tournament.event.round.end';
export const BANK_EVENTS_WIN = 'tournament.event.win';
export const BANK_EVENTS_BROKE_RECORD = 'tournament.event.playerBrokeCurrentRecord';
export const BANK_EVENTS_ANOTHER_BROKE_RECORD = 'tournament.event.win.anotherPlayerBrokeCurrentRecord';

//support
export const URL_SUPPORT = '/service/support/';

//winners
export const URL_WINNERS = '/service/dailyWinners/';

// Other
export const LIMIT = 10; // Limit count loading list
export const RUBLE_CHAR = String.fromCharCode(8381); // Format ruble's code to string
export const URL_EMAIL_PS = '/service/payment/email/code';
export const URL_WARNING = '/service/messages/warning';
export const LIVETEX_IDS = {
    1: 150151,
    2: 152519,
    3: 152520,
};
