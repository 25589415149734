import '../../../../libs/phone-mask/jquery.inputmask.bundle.min';
import '../../../../libs/phone-mask/jquery.inputmask-multi_modified';

export const maskList = $.masksSort($.masksLoad('/js/json/phone-codes.json'), ['#'], /[0-9]|#/, 'mask');
export const maskOpts = {
    inputmask: {
        definitions: {
            '#': {
                validator: '[0-9]',
                cardinality: 1
            }
        },
        showMaskOnHover: false,
        autoUnmask: true,
    },
    match: /[0-9]/,
    replace: '#',
    list: maskList,
    listKey: 'mask',
    onMaskChange: function (maskObj, completed) {
        var errorMessagesId = document.getElementById("errorMessages")
        if (errorMessagesId) {
            this.parentNode.removeChild(errorMessagesId)
        }
        if (completed) {
            let hint = maskObj.name_ru;
            if (maskObj.desc_ru && maskObj.desc_ru !== '') {
                hint += ` (${maskObj.desc_ru})`;
            }
            $('#phone-country').html(hint);
        } else {
            $('#phone-country').html('');
        }
        $(this).attr('placeholder', 'Номер телефона');
    },
    onMaskChangeMy: function(completed) {
        if (!completed) {
            var errorMessagesId = document.getElementById("errorMessages")
            if (errorMessagesId) {
                this.parentNode.removeChild(errorMessagesId)
            } else {
                const errorMessages = document.createElement('span');
                errorMessages.className = 'form-block__error form-block__error_main';
                errorMessages.textContent = 'Номер следует вводить в международном формате';
                errorMessages.id = 'errorMessages'
                this.parentNode.appendChild(errorMessages);
            }
        }
    }
/*    onMaskChangeMy: function(err) {
        var errorMessagesId = document.getElementById('errorMessages');
        if (err) {

            if (errorMessagesId) {
                return;
            }

            const errorMessages = document.createElement('span');
            errorMessages.className = 'form-block__error form-block__error_main';
            errorMessages.textContent = 'Номер следует вводить в международном формате';
            errorMessages.id = 'errorMessages'
            this.parentNode.appendChild(errorMessages);
        }
        else {
            if (errorMessagesId) {
                this.parentNode.removeChild(errorMessagesId);
            }
        }
    }*/
};


$('.form-block__input_customer-phone').inputmasks(maskOpts);

export function initMask() {
    $('.form-block__input_customer-phone').inputmasks(maskOpts);
}
