import i18next from 'i18next';
import { resources } from './resources/common';

const i18nConfig = {
    lng: 'ru',
    debug: true,
    resources: resources,
};

const detectCurrency = () => window.currencyCode || 'RUB';

const currencyIcon = () => {
    const currency = detectCurrency();

    const iconList = {
        RUB: "₽",
        USD: "$",
        EUR: "€",
        UAH: "₴"
    };
    return iconList[currency];
}

i18next.init(i18nConfig);

export { i18next as i18n, currencyIcon };
