function runCountdown(duration, update, complete) {
    let count = duration;
    const counter = setInterval(timer, 1000);

    function timer() {
        count = count - 1;
        if (count <= 0) {
            clearInterval(counter);
            complete();
            return;
        }

        update(count);
    }
}

function renderCounter(time, onStart, onComplete) {
    const wrappers = document.querySelectorAll('.js_sms-limit');
    const els = document.querySelectorAll('.js_sms-limit-time');

    function update(sec) {
        [...els].forEach((el) => el.textContent = sec);
    }

    function complete() {
        [...wrappers].forEach((wrapper) => wrapper.classList.add('hidden'));
        localStorage.removeItem('sms_time');
        onComplete && onComplete();
    }

    onStart && onStart();
    [...wrappers].forEach((wrapper) => wrapper.classList.remove('hidden'));
    runCountdown(time, update, complete);
}

export function checkCounter(counterCallbacks) {
    const smsTimeout = parseInt(localStorage.getItem('sms_time'), 10);

    if (!smsTimeout) {
        return;
    }

    const SMS_DELAY = 60;
    const timeDiff = smsTimeout ? Math.round((Date.now() - smsTimeout) / 1000) : 0;
    const { onStart, onComplete } = counterCallbacks;

    if (timeDiff >= 0 && timeDiff <= SMS_DELAY) {
        renderCounter(SMS_DELAY - timeDiff, onStart, onComplete);
    }
    else {
        localStorage.removeItem('sms_time');
    }
}


