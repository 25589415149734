import { Tabs } from 'bootstrap';
import { post } from 'axios';
import { i18n } from '../../blocks/modules/_common/i18n/i18n';

import '../../blocks/modules/_common/phone-mask/phone-mask';
import { initMask }  from '../../blocks/modules/_common/phone-mask/phone-mask';
import { checkCounter } from '../../blocks/modules/_common/form/__sms-limit/sms-limit';
import { initSelectCurrency } from '../../blocks/modules/_common/form/__select-currency/select-currency';
import { showHelpTooltip, cookieUtils, Form, convertPhone, objectFromFormData, loadCaptcha, showCaptcha, initCaptcha } from '../utils';
import { URL_REG_MAIL, URL_REG_PHONE, URL_AUTH, COUPON_COOKIE } from '../constants';


showHelpTooltip();

const isUserHasCoupon = cookieUtils.get(COUPON_COOKIE) || false;
const isNeedCaptchaReg = window.isNeedCaptchaForRegistration

const counterCallbacks = {
    onStart: function() {
        document.getElementById('btn_reg').setAttribute('disabled', 'true');
        document.getElementById('btn_restore_password').setAttribute('disabled', 'true');
    },

    onComplete: function() {
        document.getElementById('btn_reg').removeAttribute('disabled');
        document.getElementById('btn_restore_password').removeAttribute('disabled');
    }
};

/**
 * Soc login
 */
$('.fa-angle-down').click(function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    $(this).siblings('.oath-form').toggleClass('oath-form_active').stop();
    $(this).toggleClass('active').stop();
});

/**
 * Switch login popup tabs
 */
$('#btn_header_login').click(function (e) {
    e.preventDefault();
    if (isNeedCaptcha) {
        showCaptcha();
    }

    $('a[href="#login-tab"]').tab('show');
})

$('#btn_header_reg').click(function (e) {
    e.preventDefault();
    if (isNeedCaptcha) {
        showCaptcha();
    }

    $('a[href="#registration-tab"]').tab('show');
});

function redirectAfterLogin() {
    if (isUserHasCoupon) {
        location.href = '/cashier#deposit';
    }
    else {
        location.reload();
    }
}

(function ($) {
    $.fn.serializeFormJSON = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
})(jQuery);

export function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__success').remove();
}

function clearErrorMessage(el) {
    let error = el.siblings('.form-block__error');

    el.removeClass('form-block__input_error');
    if (error) {
        error.remove();
    }
}

function hideErrorMessage(el) {
    let error = el.siblings('.form-block__error');
    el.removeClass('form-block__input_error');
    error.hide();
}

function hideAllErrorMessage() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').hide();
}



export function createValidationMessages(object, error, message) {
    if (!error) {
        $(object).addClass('form-block__input_success');
        $(object).after('<span class="form-block__success">' + message + '</span>');
    } else {
        $(object).addClass('form-block__input_error');
        $(object).siblings('.form-block__error').remove();
        $(object).after('<span class="form-block__error">' + message + '</span>');
    }
}

function showErrorMessages(object, message) {
    $(object).addClass('form-block__input_error');
    $(object).next().show().text(message);
}


$(document).ready(function () {
    /**
     * Ajax request for login
     * @param { JSON } loginData - request data
     * @param { string } role
     */
    function loginAjax(loginData, role) {
        const loginBtn = document.querySelector('#btn_log')

        loginBtn.disabled = true

        $.ajax({
            type: 'POST',
            url: URL_AUTH,
            data: JSON.stringify(loginData),
            dataType: "json",
            contentType: "application/json",
            headers: {
                'X-Requested-With': ''
            },
            success: function ({ data, error }) {
                if (data.loginSuccess) {
                    redirectAfterLogin();
                } else {
                    $.each(error.items, function (i, val) {
                        if (i === 'captcha') {
                            showErrorMessages($(`#captcha-${role}`), val);
                        } else {
                            $(`#login-${role}`).addClass('form-block__input_error');
                            showErrorMessages($(`#password-${role}`), val);
                        }
                    });

                    if (data.isNeedCaptcha) {
                        showCaptcha();
                    }
                }

                loginBtn.disabled = false
            },
            error: function (request, status, error) {
                alert('Сбой сервера, попробуйте позже');
                loginBtn.disabled = false
            },
        });
    }

    function validateLogin(login) {
        if (login.value.length === 0) {
            showErrorMessages($(`#${login.id}`), 'Укажите email');
            return false;
        }

        return true;
    }

    function validatePhone(phone) {
        const pattern = /^[0-9]*$/;

        if (!phone.value) {
            showErrorMessages($(`#${phone.id}`), 'Укажите номер телефона');
            return false;
        }

        if (!pattern.test(phone.value)) {
            showErrorMessages($(`#${phone.id}`), 'Укажите корректный номер');
            return false;
        }

        return true;
    }

    function validateCaptcha(captcha) {
        if (captcha.value.length < 3) {
            showErrorMessages($('#reg_phone-captcha'), 'Укажите код')
            return false
        }

        return true
    }

    function validatePhonePassword(password) {
        if (password.value.length === 0) {
            showErrorMessages($(`#${phone.id}`), 'Введите пароль');
            return false;
        }

        return true;
    }

    function validatePassword(password) {
        const valid = /^.{8,}$/.test(password.value);

        if (password.value.length === 0) {
            showErrorMessages(
                $(`#${password.id}`),
                'Пароль не менее 8 символов: цифры и буквы в английской раскладке клавиатуры.'
            );
            return false;
        }

        if (password.id === 'password-auth') {
            return true;
        }

        if (!valid) {
            showErrorMessages(
                $(`#${password.id}`),
                'Пароль не менее 8 символов: цифры и буквы в английской раскладке клавиатуры.'
            );
            return false;
        }

        return true;
    }

    $('#form_log_mail, #form_login-returned').submit(function (e) {
        e.preventDefault();
        hideAllErrorMessage();

        const role = this.dataset.role;
        const login = document.getElementById(`login-${role}`);
        const password = document.getElementById(`password-${role}`);
        const isValid = validateLogin(login) && validatePassword(password);

        if (isValid) {
            const data = $(this).serializeFormJSON();
            loginAjax(data, role);
        }
    });


    const formRegMail = document.getElementById('form_reg_mail')
    const formRegPhone = document.getElementById('form_reg_phone')
    initSelectCurrency(formRegMail)
    initSelectCurrency(formRegPhone)


    $('#form_reg_mail').submit(function (e) {
        e.preventDefault();
        hideAllErrorMessage();

        const login = document.getElementById('login');
        const password = document.getElementById('password');
        const isValid = validateLogin(login) && validatePassword(password);

        const formValues = objectFromFormData(new FormData(e.target));
        const sendedData = {
            login: formValues.login,
            password: formValues.password,
            currency: formValues.currency,
        };

        if (isValid) {
            post(URL_REG_MAIL, sendedData)
                .then(res => res.data)
                .then(msg => {
                    if (msg.error) {
                        $.each(msg.error.items, function (i, val) {
                            showErrorMessages($('#' + i), val);
                        });
                    } else {
                        const mailForConfirm = msg.data.emailServiceAddress;
                        if (mailForConfirm) {
                            const data = {};
                            data.link = mailForConfirm;
                            data.email = msg.data.email
                            localStorage.setItem('confirm_email', JSON.stringify(data))
                        }

                        redirectAfterLogin();
                    }
                })
            }
    });

    $('#form_reg_phone').submit(function (e) {
        e.preventDefault();

        const phoneField = document.getElementById('reg_phone-login');
        const captchaPhoneField = document.getElementById('reg_phone-captcha')
        const isPhoneValid = validatePhone(phoneField);

        const formValid = isNeedCaptchaReg ? isPhoneValid && validateCaptcha(captchaPhoneField) : isPhoneValid

        if (formValid) {
            const sendedData = {
                login: convertPhone(phoneField.value),
                currency: objectFromFormData(new FormData(e.target)).currency,
                password: '',
                captcha:  isNeedCaptchaReg ? captchaPhoneField.value : '',
            };

            post(URL_REG_PHONE, sendedData)
                .then(res => res.data)
                .then(data => {
                    if (!data.error) {
                        $('#msg_greating').addClass('hidden');
                        $('#msg_reg_phone').removeClass('hidden');
                        $('#password-auth-label').text(i18n.t('passwordTooltipAfterReg'));
                        $('a[href="#login-tab"]').tab('show');
                        $('#log_phone').change();

                        document.getElementById('log_phone').click();
                        document.getElementById('login-auth').value = sendedData.login;

                        localStorage.setItem('sms_time', Date.now());
                        checkCounter(counterCallbacks);
                    }
                    else {
                        $.each(data.error.items, function (i, val) {
                            createValidationMessages($('#reg_phone-' + i), true, val)
                        });

                        if (isNeedCaptchaReg) {
                            loadCaptcha()
                            $('#reg_phone-captcha').val('')
                        }
                    }
                })
        }
    })


    $('input:not(.js-show-val), textarea').on('focus paste', function () {
        hideErrorMessage($(this));
    });

    // $('#login-auth, #login-return').blur(function () {
    //     const id = $(this).attr('id');
    //     const login = document.getElementById(id);
    //     validateLogin(login);
    // });

    $('#password').blur(function () {
        const password = document.getElementById('password');
        validatePassword(password);
    });

    $('#form_send_email-popup').submit(function (e) {
        e.preventDefault();
        clearValidationMessages();
        var data = $(this).serializeFormJSON();
        $.ajax({
            type: 'POST',
            url: " /service/player/sendPasswordCode",
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            success: function (msg) {
                if (!msg.error) {
                    $('#pass-send-mail .mfp-close').click();
                    $.magnificPopup.open({
                        items: {
                            src: '#pass-sent',
                        },
                        type: 'inline',
                        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
                    });
                } else {
                    $.each(msg.error.items, function (i, val) {
                        createValidationMessages($('#send_email-' + i), true, val)
                    });
                }
                loadCaptcha();

            },
            error: function (request, status, error) {
                $.each(request.error.items, function (i, val) {
                    createValidationMessages($('#send_email-' + i), true, val)
                });
                loadCaptcha();
            },
        });
    });

    $('#form_send_phone-popup').submit(function (e) {
        e.preventDefault();
        clearValidationMessages();
        var data = $(this).serializeFormJSON();

        $.ajax({
            type: 'POST',
            url: " /service/player/passwords",
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            success: function (msg) {
                if (!msg.error) {
                    $('#pass-send-mail .mfp-close').click();
                    $.magnificPopup.open({
                        items: {
                            src: '#pass-sent-phone',
                        },
                        type: 'inline',
                        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
                    });

                    localStorage.setItem('sms_time', Date.now());
                    checkCounter(counterCallbacks);
                }
                else {
                    if (!msg.error.items.captcha) {
                        localStorage.setItem('sms_time', Date.now());
                        checkCounter(counterCallbacks);
                    }

                    $.each(msg.error.items, function (i, val) {
                        createValidationMessages($('#send_phone-' + i), true, val)
                    });
                }
                loadCaptcha();
                $('#send_phone-captcha').val('');

            },
            error: function (request, status, error) {
                $.each(request.error.items, function (i, val) {
                    createValidationMessages($('#send_phone-' + i), true, val)
                });
                loadCaptcha();
                $('#send_phone-captcha').val('');
            },
        });
    });

    initCaptcha()
});

// let formElement = document.getElementById('form_registration-popup');
// if (formElement) {
//     let formBtnSubmit = formElement.querySelector('.btn_reg');
//     let form = new Form(formElement, formBtnSubmit);
// }




const PHONE = 'phone';

function toggleTabLog(id) {
    const type = id.split('_')[1];
    const input = document.getElementById('login-auth');
    const link = document.getElementById('forgot-link');

    const labelText = type === PHONE ? 'Введите свой номер телефона' : 'Введите свой e-mail';
    const placeholderText = type === PHONE ? 'Телефон' : 'Email';
    const tooltipText = type === PHONE ? 'Введите свой номер телефона' : 'Введите email';

    if (type === PHONE) {
        input.classList.add('form-block__input_customer-phone');
        initMask();
    }
    else {
        input.classList.remove('form-block__input_customer-phone');
        $('#login-auth').inputmasks('remove');
    }


    hideAllErrorMessage();

    document.querySelector('label[for="login-auth"').textContent = labelText;
    input.value = '';
    input.setAttribute('placeholder', placeholderText);

    const btnTabPhone = document.getElementById('pass_phone')
    const btnTabMail = document.getElementById('pass_mail')

    // dispatch event change for restore form
    if (type === PHONE) {
        link.dataset.type = 'phone';
        btnTabPhone.click()
        btnTabPhone.checked = true
    }
    else {
        link.dataset.type = 'mail';
        btnTabMail.click()
        btnTabMail.checked = true
    }

    document.querySelector(`#form_log_mail .form-block__tooltip_email`).textContent = tooltipText;
}

function toggleTabReg() {
    document.getElementById('tab_reg_mail').classList.toggle('hidden');
    document.getElementById('tab_reg_phone').classList.toggle('hidden');
}

function toggleTabPass() {
    loadCaptcha();
    document.getElementById('tab_pass_mail').classList.toggle('hidden');
    document.getElementById('tab_pass_phone').classList.toggle('hidden');
}


// onChange Tabs listeners
const tabTypeReg = document.getElementById('registration-tab');
const tabTypeLog = document.getElementById('login-tab');
const tabTypePass = document.getElementById('pass-send-control');

if (tabTypeReg && tabTypeLog) {
    [...tabTypeReg.querySelectorAll('input[type=radio]')].forEach((input) => {
        input.addEventListener('change', (e) => {
            toggleTabReg();
        });
    });

    [...tabTypeLog.querySelectorAll('input[type=radio]')].forEach((input) => {
        input.addEventListener('change', (e) => {
            toggleTabLog(e.target.id);
        });
    });
}

if (tabTypePass) {
    [...tabTypePass.querySelectorAll('input[type=radio]')].forEach((input) => {
        input.addEventListener('change', (e) => {
            toggleTabPass();
        });
    })
}


checkCounter(counterCallbacks);

function checkRules(checkbox, submitBtn) {
    const rulesError = document.createElement('span');
    rulesError.className = 'form-block__error form-block__error_rules';
    rulesError.textContent = 'Вы должны согласиться с правилами казино';
    if (checkbox.checked) {
        checkbox.classList.remove('checkbox-wrapp__checkbox_error');
        submitBtn.removeAttribute('disabled');
        $('.form-block__error_rules').remove();
    } else {
        checkbox.classList.add('checkbox-wrapp__checkbox_error');
        checkbox.parentNode.appendChild(rulesError);
        submitBtn.setAttribute('disabled', 'disabled');
    }
}

const regForms = [document.getElementById('form_reg_mail'), document.getElementById('form_reg_phone')];

if (regForms[0] && regForms[1]) {
    regForms.forEach((form) => {
        const checkbox = form.querySelector('input[type=checkbox]');
        const submitBtn = form.querySelector('button[type=submit]');

        checkbox.addEventListener('change', () => checkRules(checkbox, submitBtn));
    });
}

if (isNeedCaptchaReg) {
    loadCaptcha()
}
